import {
  Box,
  Container
} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';
import styled from '@emotion/styled'
import './web.css'
function App() {
  return (
    <Box>
      <body dark-scroll="">
        <input
          type="hidden"
          id="anPageName"
          name="page"
          value="homepage-scrolling"
        />
        <div class="container-center-horizontal">
          <div class="homepage-scrolling screen" data-id="26:1894">
            <div class="frame-48097255-iSxdfu" data-id="26:1895">
              <div
                class="hero-5wqvWQ"
                data-id="26:1896-cdb084d2-8c92-49cd-bb6f-7343d09c765c"
              >
                <div class="frame-48097258-fH68vY" data-id="26:1913">
                  <div class="frame-48097257-VclwSc" data-id="26:1914">
                    <div class="frame-48097256-XMFvzn" data-id="26:1915">
                      <h1
                        class="smart-thinking-innovative-solution-ZzSNJy displaybold"
                        data-id="26:1916"
                      >
                        Smart-thinking &amp; Innovative Solution.
                      </h1>
                      <p
                        class="experience-a-rise-in-ZzSNJy mainregular"
                        data-id="26:1917"
                      >
                        We offer a full cycle of web app development. Our experienced specialists constantly improve their skills to deliver top-notch development services. Get a custom web app, and start your business idea.
                        
                      </p>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="services-5wqvWQ" data-id="26:8849">
                <img
                  class="ellipse-477-RUZwKx"
                  data-id="26:8850"
                  src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/ellipse-477.svg"
                  alt="Ellipse 477"
                />
                <div class="group-2538-RUZwKx" data-id="26:8851">
                  <div class="frame-48097268-5AHL7s" data-id="26:8852">
                    <div class="title-YdTrKm title" data-id="26:8853">
                      <div class="frame-48097267-ljHfBF" data-id="26:8854">
                        <div
                          class="our-service-2msIm8 mainuppercase"
                          data-id="26:8855"
                        >
                          OUR SERVICE
                        </div>
                        <p
                          class="performance-is-the-k-2msIm8 titlebold"
                          data-id="26:8856"
                        >
                          By choosing the right technologies and approach, we help businesses grow and achieve success.
                        </p>
                      </div>
                      <p
                        class="with-our-vision-of-p-ljHfBF mainregular"
                        data-id="26:8857"
                      >
                        With expert knowledge gained over the years and a lot of projects behind us, we build innovative solutions for companies across various industries. We know how to meet the expectations of both our clients and the market in which they operate.
                      </p>
                    </div>
                    <div class="content-YdTrKm" data-id="26:8858">
                      <div class="frame-2537-32Xvrb" data-id="26:8859">
                        <img
                          class="line-94-1TxcCy"
                          data-id="26:8860"
                          src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/line-94.svg"
                          alt="Line 94"
                        />
                        <div class="frame-48097269-1TxcCy" data-id="26:8867">
                          <div class="frame-48097280-TJTzQk" data-id="26:8868">
                            <div class="frame-48097270" data-id="26:8869">
                              <div
                                class="blockchain-development-hR5WNP valign-text-middle blockchain-development subtitlebold"
                                data-id="I26:8869;18:676"
                              >
                                Blockchain Development
                              </div>
                            </div>
                            <p
                              class="support-your-busines-J9x12Q mainregular"
                              data-id="26:8870"
                            >
                              Our software company specializes in Blockchain Development, offering cutting-edge solutions for businesses looking to leverage the power of blockchain technology.
                            </p>
                            <div
                              class="frame-48097273-J9x12Q frame-48097273"
                              data-id="26:8871"
                            >
                            </div>
                          </div>
                        </div>
                        <img
                          class="line-210-1TxcCy"
                          data-id="26:8862"
                          src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/line-210.svg"
                          alt="Line 210"
                        />
                         <div class="frame-48097269-1TxcCy" data-id="26:8867">
                          <div class="frame-48097280-TJTzQk" data-id="26:8868">
                            <div class="frame-48097270" data-id="26:8869">
                              <div
                                class="blockchain-development-hR5WNP valign-text-middle blockchain-development subtitlebold"
                                data-id="I26:8869;18:676"
                              >
                                Mobile App Development
                              </div>
                            </div>
                            <p
                              class="support-your-busines-J9x12Q mainregular"
                              data-id="26:8870"
                            >
                              Our Mobile App Development service caters to businesses seeking innovative solutions for their digital presence. With a focus on user-friendly interfaces and cutting-edge technologies, we create custom mobile applications that stand out in today's competitive market.
                            </p>
                            <div
                              class="frame-48097273-J9x12Q frame-48097273"
                              data-id="26:8871"
                            >
                            </div>
                          </div>
                        </div>
                        <img
                          class="line-211-1TxcCy"
                          data-id="26:8864"
                          src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/line-210.svg"
                          alt="Line 211"
                        />
                        <div class="frame-48097269-1TxcCy" data-id="26:8867">
                          <div class="frame-48097280-TJTzQk" data-id="26:8868">
                            <div class="frame-48097270" data-id="26:8869">
                              <div
                                class="blockchain-development-hR5WNP valign-text-middle blockchain-development subtitlebold"
                                data-id="I26:8869;18:676"
                              >
                                Web Development
                              </div>
                            </div>
                            <p
                              class="support-your-busines-J9x12Q mainregular"
                              data-id="26:8870"
                            >
                              Our Web Development service offers tailored solutions to meet your specific needs.
                              We stay up-to-date with the latest technologies to provide you with modern and innovative web solutions. Your website will always be ahead of the curve.
                            </p>
                            <div
                              class="frame-48097273-J9x12Q frame-48097273"
                              data-id="26:8871"
                            >
                            </div>
                          </div>
                        </div>
                        <img
                          class="line-212-1TxcCy"
                          data-id="26:8866"
                          src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/line-210.svg"
                          alt="Line 212"
                        />
                        <div class="frame-48097269-1TxcCy" data-id="26:8867">
                          <div class="frame-48097280-TJTzQk" data-id="26:8868">
                            <div class="frame-48097270" data-id="26:8869">
                              <div
                                class="blockchain-development-hR5WNP valign-text-middle blockchain-development subtitlebold"
                                data-id="I26:8869;18:676"
                              >
                                Software Development
                              </div>
                            </div>
                            <p
                              class="support-your-busines-J9x12Q mainregular"
                              data-id="26:8870"
                            >
                              Support your business infrastructure with software
                              development projects that improve crucial facets
                              of your business.
                              <br />
                              Get access to our industry-specific knowledge to
                              plan, construct, and scale your new custom
                              software development
                            </p>
                            <div
                              class="frame-48097273-J9x12Q frame-48097273"
                              data-id="26:8871"
                            >
                            </div>
                          </div>
                        </div>
                        <img
                          class="line-214-1TxcCy"
                          data-id="26:8878"
                          src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/line-210.svg"
                          alt="Line 214"
                        />
                      </div>
                      <div
                        class="frame-48097274-32Xvrb"
                        data-id="26:8881-3fcbeec3-dcbc-4417-9ad3-b2c1235cc9c4"
                      >
                        <img
                          class="rectangle-1538-oFq1qB"
                          data-id="26:8882"
                          src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                          anima-src=""
                          alt="Rectangle 1538"
                        />
                        <img
                          class="frame-47-oFq1qB frame-47"
                          data-id="26:8888"
                          src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/frame-47-5.png"
                          alt="Frame 47"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="strong-point-5wqvWQ" data-id="26:2962">
                <div class="frame-48097300-GsEP8v" data-id="26:2963">
                  <div class="group-2570-gcHQxk" data-id="26:2964">
                    <div class="group-2552-PF7ylu" data-id="26:2965">
                      <div class="group-2546-8PNBDn" data-id="26:2966">
                        <div
                          class="rectangle-1527-0A5cZc"
                          data-id="26:2967"
                        ></div>
                        <div class="group-2545-0A5cZc" data-id="26:2968">
                          <img
                            class="vector-aOMFJv vector"
                            data-id="26:2969"
                            src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/vector.svg"
                            alt="Vector"
                          />
                          <div class="group-2540-aOMFJv" data-id="26:2970">
                            <div
                              class="awesome-design-xsxIU7 subtitlebold"
                              data-id="26:2971"
                            >
                              Awesome Design
                            </div>
                            <p
                              class="integer-a-elit-pelle-xsxIU7 mainregular"
                              data-id="26:2972"
                            >
                              
                            </p>
                            <img
                              class="image-23-xsxIU7"
                              data-id="26:2973"
                              src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/image-23.png"
                              alt="image 23"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="group-2544-8PNBDn" data-id="26:2974">
                        <div
                          class="rectangle-1529-xPpuxU"
                          data-id="26:2975"
                        ></div>
                        <img
                          class="vector-xPpuxU vector"
                          data-id="26:2976"
                          src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/vector.svg"
                          alt="Vector"
                        />
                        <div class="group-2542-xPpuxU" data-id="26:2977">
                          <div
                            class="easy-solutions-y1woIE subtitlebold"
                            data-id="26:2978"
                          >
                            Easy Solutions
                          </div>
                          <p
                            class="aenean-eget-nulla-eu-y1woIE mainregular"
                            data-id="26:2979"
                          >
                            
                          </p>
                          <img
                            class="image-26-y1woIE"
                            data-id="26:2980"
                            src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/image-26.png"
                            alt="image 26"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="group-2551-PF7ylu" data-id="26:2981">
                      <div class="group-2543-6iaAmx" data-id="26:2982">
                        <div
                          class="rectangle-1528-1lujVM"
                          data-id="26:2983"
                        ></div>
                        <img
                          class="vector-1lujVM vector"
                          data-id="26:2984"
                          src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/vector.svg"
                          alt="Vector"
                        />
                        <div class="group-2541-1lujVM" data-id="26:2985">
                          <div
                            class="best-features-Sf3aoz subtitlebold"
                            data-id="26:2986"
                          >
                            Best Features
                          </div>
                          <p
                            class="fusce-risus-lorem-pu-Sf3aoz mainregular"
                            data-id="26:2987"
                          >
                            
                          </p>
                          <img
                            class="image-24-Sf3aoz"
                            data-id="26:2988"
                            src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/image-24.png"
                            alt="image 24"
                          />
                        </div>
                      </div>
                      <div class="group-2550-6iaAmx" data-id="26:2989">
                        <div class="group-2547-wa5uiN" data-id="26:2990">
                          <div
                            class="rectangle-57-rhnB34"
                            data-id="26:2991"
                          ></div>
                          <img
                            class="vector-rhnB34 vector"
                            data-id="26:2992"
                            src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/vector.svg"
                            alt="Vector"
                          />
                          <div class="group-2539-rhnB34" data-id="26:2993">
                            <div
                              class="creative-ideas-Vzqcfx subtitlebold"
                              data-id="26:2994"
                            >
                              Creative Ideas
                            </div>
                            <p
                              class="class-aptent-taciti-Vzqcfx class-aptent-taciti mainregular"
                              data-id="26:2995"
                            >

                            </p>
                            <img
                              class="image-19-Vzqcfx"
                              data-id="26:2996"
                              src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/image-19.png"
                              alt="image 19"
                            />
                          </div>
                        </div>
                        <div class="bg-wa5uiN bg" data-id="26:2997"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cta-5wqvWQ" data-id="26:8975">
                <div class="frame-208-j7Da54" data-id="26:8976">
                  <div class="frame-42-5sqeDv frame-42" data-id="26:8977">
                    <p
                      class="lets-collaborate-for-mutual-success-ftKtsv titlebold"
                      data-id="26:8978"
                    >
                      Let's Collaborate for Mutual Success
                    </p>
                    <p
                      class="your-success-story-b-ftKtsv mainregular"
                      data-id="26:8979"
                    >
                      Your success story begins with a click, explore our technology solutions for transformative results.
                    </p>
                  </div>
                </div>
                <img
                  class="image-11-j7Da54"
                  data-id="26:9156"
                  src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/image-11.png"
                  alt="image 11"
                />
                <img
                  class="frame-47-j7Da54 frame-47"
                  data-id="26:9157"
                  src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/frame-47.png"
                  alt="Frame 47"
                />
              </div>
              <div class="footer-5wqvWQ" data-id="26:3354">
                <div class="footer-zlmStk" data-id="26:3355">
                  <div class="main-footer-2Singj" data-id="26:3356">
                    <div class="frame-48097294-3Otc3O" data-id="26:3357">
                      <div class="frame-48097289-B6GKgF" data-id="26:3358">
                        <div class="frame-48097288-JscQvK" data-id="26:3359">
                          <div class="logo-Wt1qfw logo" data-id="26:3360">
                            <img
                              class="icon-container"
                              data-id="26:3361"
                              src={process.env.PUBLIC_URL + "/assets/logo.png"}
                              alt="Icon Container"
                              width={140}
                              height={120}
                            />
                          </div>
                          <p
                            class="get-in-touch-on-us-f-Wt1qfw titlebold"
                            data-id="26:3366"
                          >
                            Get in Touch on Us for Your Path to Success
                          </p>
                          <div
                            class="frame-48097287-Wt1qfw frame-48097287"
                            data-id="26:3367"
                          >
                            <div
                              class="frame-48097285-TuTS0O"
                              data-id="26:3368"
                            >
                              <img
                                class="phone_enabled-MNRLEA"
                                data-id="26:3369"
                                src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/phone-enabled-2.svg"
                                alt="phone_enabled"
                              />
                              <div
                                class="x888-1234-5678-MNRLEA montserrat-medium-white-16px"
                                data-id="26:3370"
                              >
                                +1 (435) 288-3353
                              </div>
                            </div>
                            <div
                              class="frame-48097286-TuTS0O"
                              data-id="26:3374"
                            >
                              <img
                                class="location_on-DvM9xd"
                                data-id="26:3375"
                                src="https://cdn.animaapp.com/projects/5fe2e425c2be9e06dafb5d24/releases/66433a8c53907e7b0470fd2e/img/location-on-2.svg"
                                alt="location_on"
                              />
                              <p
                                class="x772-abc-street-usa-000000-DvM9xd montserrat-medium-white-16px"
                                data-id="26:3376"
                              >
                                169 W 2710 S Cir Ste 202A <br/> St George UT 84790 United States
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sub-footer-2Singj" data-id="26:3399">
                    <p
                      class="x2024-company-brand-u-wxpMfg smallregular"
                      data-id="26:3400"
                    >
                      © 2024 3B techs. All rights reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <header class="header-iSxdfu" data-id="26:3409">
              <div class="frame-1351-GqFtBP" data-id="I26:3409;24:1702">
                <div class="logo-bgz919 logo" data-id="I26:3409;24:1703">
                  <img
                    class="icon-container"
                    data-id="I26:3409;24:1704"
                    src={process.env.PUBLIC_URL + "/assets/logo.png"}
                    alt="Icon Container"
                    width={140}
                    height={120}
                  />
                </div>
              </div>
            </header>
          </div>
        </div>
      </body>
    </Box>
  )
}

export default App

const LinkStyled = styled.a`
  textDecoration: 'auto',
  color: 'unset',
  width: '100%',
`
